<template>
  
<FormKitProvider :config="__formkitConfig">
<FormKit
    :id="id"
    type="form"
    :actions="false"
    :classes="{
      form: 'flex flex-col space-y-4',
    }"
    @submit="submitForm"
  >
    <FormKitSchema :schema="JSON.parse(schema)" />

    <Button
      :theme="BUTTON_THEME.PRIMARY"
      :type="BUTTON_TYPE.SUBMIT"
      :text="t('submit')"
    />
  </FormKit>
</FormKitProvider>

</template>

<script setup lang="ts">
import { FormKitProvider } from "@formkit/vue";
import __formkitConfig from "virtual:formkit-config";
import { FormKitSchema } from '@formkit/vue'

interface Props {
  id: string
  schema: any
}

const { id, schema } = defineProps<Props>()

const { currentUrl } = useCurrentUrl()
const { add } = useMessage()
const { t } = useI18n()

async function submitForm(formData: object) {
  const payload = {
    url: currentUrl.value,
    formId: id,
    formData: Object.entries(formData).map(([name, value]) => ({
      name,
      value,
    })),
  }

  const { data, error } = await useAsyncGql('SubmitFormBuilderForm', payload)

  handleResponse(data, error)
}

function handleResponse(data: Ref<any>, error: Ref<any>) {
  const hasError = !!error.value
  const message = hasError
    ? error.value?.message || t('submitError')
    : data.value?.message || t('submitSuccess')
  const severity = hasError ? MESSAGE_SEVERITY.ERROR : MESSAGE_SEVERITY.SUCCESS

  add({
    message,
    severity,
    type: MESSAGE_TYPE.TOAST,
  })
}

defineOptions({
  name: 'FormBuilder',
})
</script>

<i18n>
de:
  submit: 'Abschicken'
  submitError: 'Leider konnte das Formular nicht abgeschickt werden. Bitte versuchen Sie es später erneut.'
  submitSuccess: 'Das Formular wurde erfolgreich abgeschickt. Danke für Ihre Nachricht.'
es:
  submit: 'Enviar'
  submitError: 'Lo sentimos, no se pudo enviar el formulario. Por favor, inténtelo de nuevo más tarde.'
  submitSuccess: 'El formulario se ha enviado correctamente. Gracias por su mensaje.'
</i18n>
