import { createInput } from '@formkit/vue'

const numberInputSchema = [
  {
    $el: 'button',
    children: [
      {
        $el: 'span',
        children: '-',
      },
    ],
    attrs: {
      class: '$classes.decrement',
      onClick: '$handlers.decrement()',
      type: 'button',
    },
  },
  {
    $el: 'input',
    bind: '$attrs',
    attrs: {
      id: '$id',
      name: '$name',
      type: 'number',
      class: '$classes.input',
      onInput: '$handlers.DOMInput',
      onBlur: '$handlers.blur',
      disabled: '$disabled',
      value: '$_value',
    },
  },
  {
    $el: 'button',
    children: [
      {
        $el: 'span',
        children: '+',
      },
    ],
    attrs: {
      class: '$classes.increment',
      onClick: '$handlers.increment()',
      type: 'button',
    },
  },
]

/**
 * Here we add our custom handlers to support
 * the unique needs of our input.
 */
function addHandlers(node) {
  node.on('created', () => {
    node.context.handlers.increment = () => () => {
      const value = parseInt(node.value) ?? 0
      const newValue = value + (node.context.step ?? 1)

      if (
        node.context.disabled ||
        (node.context.max && newValue > node.context.max)
      ) {
        return
      }

      node.input(newValue)

      node.on('settled', () => {
        setTimeout(() => {
          node.context.handlers.blur()
        }, 0)
      })
    }

    node.context.handlers.decrement = () => () => {
      const value = parseInt(node.value) ?? 0
      const newValue = value - (node.context.step ?? 1)

      if (
        node.context.disabled ||
        (node.context.min && newValue < node.context.min)
      ) {
        return
      }

      node.input(newValue)

      node.on('settled', () => {
        setTimeout(() => {
          node.context.handlers.blur()
        }, 0)
      })
    }
  })

  node.on('dom-input-event', () => {
    if (node.context.min && parseInt(node._value) < node.context.min) {
      node.input(node.context.min)
    }

    if (node.context.max && parseInt(node._value) > node.context.max) {
      node.input(node.context.max)
    }
  })
}

export default createInput(numberInputSchema, {
  features: [addHandlers],
  props: ['disabled', 'min', 'max', 'step'],
})
